<template>
  <div>
    <el-dialog
      class="AddDialog"
      title="兑换"
      :visible.sync="dialogVisible"
      width="800px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
      >
        <el-row :gutter="15">
          <el-col :span="13">
            <el-form-item label="兑换用户id" prop="uid">
              <el-input v-model="ruleForm.uid" @blur="getUserPiece(ruleForm.uid)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <span style="font-size:16px;line-height:40px">(剩余{{userPiece}}积分)</span>
          </el-col>
          <el-col :span="13">
            <el-form-item label="兑换商品id" prop="eid">
              <!-- <el-input
                v-model="ruleForm.gid"
                style="width: 180px"
                placeholder="请输入商品id"
              ></el-input> -->
              <template>
              <el-select v-model="ruleForm.eid" placeholder="请选择">
                <el-option
                  v-for="item in goodsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
            </el-form-item>
          </el-col>
          
          <el-col :span="13">
            <el-form-item label="兑换数量" prop="num">
              <template>
              <el-input-number v-model="ruleForm.num" :min="1" label="描述文字"></el-input-number>
            </template>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="电话号码" prop="num">
              <template>
              <el-input v-model="ruleForm.tel" label="描述文字"></el-input>
            </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { exchGoods,createExch,userPiece} from "@/request/api";
export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      id: "",
      token: "",
      type: "", //1新增，2编辑
      num:1,
      dialogVisible: false,
      ruleForm: {
        uid: "",
        eid: "",
        num: "",
        tel:''
      },
      userPiece:'',
      goodsList:[],
      rules: {
        gid: [{ required: true, message: "请选择兑换商品", trigger: "blur" }],
        uid: [{ required: true, message: "请输入兑换用户id", trigger: "blur" }],
        num: [{ required: true, message: "请选择兑换数量", trigger: "blur" }],
        tel: [{ required: true, message: "请选择兑换用户的电话号码", trigger: "blur" }],
      },
    };
  },
  created() {
    
  },
  mounted: function () {
    this.getgoodsEdit();
  },
  methods: {
    //获取修改的信息
     getgoodsEdit() {
      let params = {
        token:sessionStorage.getItem("token")
      }
      exchGoods(params).then((res) => {
        // console.log(res)
        this.goodsList=res.data.data
      })
    },
    show(type, row) {
      this.type = type;
      this.dialogVisible = true;
      // if (type == 1) {
      //   this.ruleForm = {
      //     gid: "",
      //     days: "",
      //     type: "",
      //   };
      // } else {
      //   let id = row.id;
      //   this.id = id;
      //   let params = {
      //     token: sessionStorage.getItem("token"),
      //     id: id,
      //   };
      //   goodseditshow(params).then((res) => {
      //     this.ruleForm = res.data.data;
      //   });
      // }
    },
    close() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate(); //关闭清空校验规则
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
         
            let token = sessionStorage.getItem("token");
            this.token = token;
            let params = {
              uid: this.ruleForm.uid,
              num: this.ruleForm.num,
              token: sessionStorage.getItem("token"),
              eid: this.ruleForm.eid,
            };
          createExch(params).then((res) => {
              // console.log(res)
              if (res.data.code == 200) {
                this.$message.success("兑换成功");
                this.$parent.getexchList();
                this.close();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        
          this.$parent.getexchList();
          this.close();
        } else {
          return false;
        }
      });
    },
    // 根据用户id获取用户积分
    getUserPiece(id) {
      // console.log(id)
      let params = {
        token: sessionStorage.getItem("token"),
        uid:id
      }
      userPiece(params).then((res) => {
        // console.log(res)
        if (res.data.code == 200) {
          this.userPiece=res.data.data
        } else {
          // this.userPiece="请输入正确的用户ID"
        }
        // console.log(res)
      })
    }
  },
};
</script>

<style>
</style>
