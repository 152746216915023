<template>
  <div>
    <el-form :inline="true">
      <el-form-item style="float: right">
        <el-button type="primary" @click="add()">兑换</el-button>
      </el-form-item>
    </el-form>

    <page-table
      ref="dataTable"
      :data="historyList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户ID" align="center" prop="uid">
      </el-table-column>
       <el-table-column label="兑换份数" align="center" prop="num">
      </el-table-column>
        <el-table-column label="总消耗积分" align="center" prop="piece_num">
      </el-table-column>
      <el-table-column label="名称" align="center" prop="name">
      </el-table-column>
      <el-table-column label="兑换时间" align="center" prop="created_at">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="open(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </page-table>
    <exchDia ref="exchDia" />
  </div>
</template>
<script>
 import { exchHistory ,exchDel} from "@/request/api";
 import { checkPermission } from "@/utils/permissions";
import exchDia from "./components/exchDia.vue";
 import pageTable from "@/components/pageTable.vue";
 export default {
   components: {
    exchDia,
     pageTable,
   },
   data() {
     return {
       historyList: [],
       page: {
         //分页信息
         currentPage: 1, //当前页
         pageSize: 10, //每页条数
         total: "", //总条数
       }
      };
   },
   created() {
     this.getexchList();
   },
   computed: {},
   methods: {
    checkPermission,
     // 切换分页
     changeCurrent(page, size) {
       this.page.currentPage = page;
       this.page.pageSize = size;
       this.getexchList();
     },
     //积分兑换记录列表
     getexchList() {
       let params = {
         token: sessionStorage.getItem("token"),
         page: this.page.currentPage,
         limit:this.page.pageSize
         
       };
       exchHistory(params).then((res) => {
        // console.log(res)
        this.historyList = res.data.list.data;
         this.page.total = res.data.list.total;
         this.$refs.dataTable.setPageInfo({
           total: this.page.total,
         });
       });
     },
     //删除积分兑换记录
     open(row) {
       this.$confirm("此操作将永久删除, 是否继续?", "提示", {
         confirmButtonText: "确定",
         cancelButtonText: "取消",
         type: "warning",
       })
         .then(async () => {
           let id = row.id;
           let params = {
             token: sessionStorage.getItem("token"),
             id: id,
           };
           exchDel(params).then((res) => {
             if (res.data.code == 200) {
               this.$message.success("删除成功");
               this.getexchList();
             } else {
               this.$message.error(res.data.msg);
               this.getexchList();
             }
           });
         })
         .catch(() => {
           this.$message({
             type: "info",
             message: "已取消删除",
           });
         });
     },
     //展示兑换弹窗
     add() {
      this.$refs.exchDia.show()
     }
   },
 };
</script>
<style  scoped>
.xun {
  font-size: 14px;
  color: rgb(151, 151, 151);
  padding-bottom: 5px;
  padding-top: 10px;
  margin-left: 200px;
}
.tab {
  margin-left: 50px;
}
.bt {
  padding: 10px 30px;
  margin-top: 100px;
  margin-left: 600px;
}

.xunre {
  margin: 50px 400px;
}
.tijiao {
  margin-top: 110px;
}
</style>
